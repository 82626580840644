import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import { EquityIntoCashQuery } from '../../../../../graphql-types';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import ParallaxBackground from '../../../parallax-background';
import * as style from './index.module.scss';

export default function EquityIntoCash() {
  const data: FeatureSectionProps = {
    title: 'Use your home to get a loan.',
    fullLengthTitle: true,
    items: [
      {
        align: 'right',
        title: 'Turn your home equity into the cash you need.',
        subtitle: '',
        description: `Equity loans don't have to be expensive, they just need to solve a problem and come with a detailed plan. At Nuborrow, we only provide home equity loans backed by a three-year savings plan.`,
        actions: [
          { label: `Apply Today For Free`, href: 'https://apply.nuborrow.com', isPrimary: true }
        ],
        art: equityIntoCashArt()
      }
    ]
  };

  return <FeatureSection {...data} />;
}

export const equityIntoCashArt = () => {
  const bgImage = useStaticQuery<EquityIntoCashQuery>(dataQuery);

  return (
    <div className={'d-flex align-items-center justify-content-center'}>
      <ParallaxBackground
        className={style.equityArt}
        image={bgImage.file as unknown as ImageDataLike}
      />
    </div>
  );
};

const dataQuery = graphql`
  query EquityIntoCash {
    file(relativePath: { glob: "photos/equity-into-cash.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.625, width: 1000)
      }
      relativePath
    }
  }
`;
