import React from 'react';
import MotionAnimatedCounter from '../../../animated-counter';
import { SectionDescription } from '../../../section-description';
import SectionTitle from '../../../section-title';
import * as style from './index.module.scss';

export default function TotalSavings(props: { value: number }) {
  return (
    <section
      className={
        style.totalSavings +
        ' m-auto d-block d-md-flex flex-column align-items-center justify-content-center'
      }
    >
      <div
        className={
          style.wrapper +
          ' container text-center py-4 d-flex flex-column align-items-stretch align-items-sm-center'
        }
      >
        <SectionTitle>Thousands of clients have saved millions with Nuborrow.</SectionTitle>
        <div className={style.counterBlock + ' d-flex flex-column align-items-center'}>
          <MotionAnimatedCounter
            className={style.counter}
            initialValue={3000000}
            value={props.value}
            duration={2000}
            decimals={2}
            prefix={'$ '}
          />
        </div>
        <SectionDescription className={style.desc + ' my-4'}>
          <span>
            <span className={"d-block h3"}>Over 6 MILLION saved (and growing)</span>
          </span>
          <br />
          <span>
            Reflects both the payment and interest savings created for our clients since 2014 and
            includes those who have refinanced, added an equity based product, or did both.
          </span>
        </SectionDescription>
      </div>
    </section>
  );
}
