import { motion, useMotionValue, useTransform } from 'framer-motion';
import React, { useEffect } from 'react';
import { useViewportIntersection } from '../../../../hooks/use-viewport-intersection';
import VisualTitle from '../../../visual-title';
import * as style from './index.module.scss';

export default function FullBlock() {
  const [ref, ratio] = useViewportIntersection<HTMLDivElement>();

  const scrollPos = useMotionValue(0);
  const translateY = useTransform(scrollPos, [-1, 0, 1], [-50, 0, 50]);

  useEffect(() => scrollPos.set(ratio), [ratio]);

  return (
    <section
      className={
        style.fullBlock + ' d-block d-md-flex flex-column align-items-center justify-content-center'
      }
    >
      <div ref={ref} className="container-fluid">
        <motion.div className="container text-center" style={{ translateY }}>
          <VisualTitle className={style.title}>
            Save more money, improve your credit score, and waive goodbye to credit card interest.
          </VisualTitle>
        </motion.div>
      </div>
    </section>
  );
}
