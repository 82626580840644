import React, { useState } from 'react';
import Layout from '../components/layout';
import RateBanner, { RateBannerProps } from '../components/rate-banner';
import RateComparison, {
  RateComparisonItem,
  RateComparisonProps
} from '../components/rate-comparison';
import EquityIntoCash from '../components/sections/home/equity-into-cash';
import TrackYourApp from '../components/sections/line/track-app';
import UseEquity from '../components/sections/line/use-equity';
import FullBlock from '../components/sections/loan/full-block';
import TotalSavings from '../components/sections/loan/savings';
import DownloadApp from '../components/sections/shared/download';
import Reviews from '../components/sections/shared/reviews';
import SaveMoney from '../components/sections/shared/save-money';
import TrackingPixel from '../components/tracking-pixel';
import Nuborrow from '../images/nuborrow_logo.svg';
import { RootState } from '../utils/root-state';

export default function EquityLoanPage() {
  const [rate, setRate] = useState(0);
  const [effectiveDate, setEffectiveDate] = useState(new Date(1970, 0));

  const rateProps: RateBannerProps = {
    title: 'Use your home to get the loan you need.',
    rate,
    description: (
      <>
        <span>
          Why are we using high-interest credit cards when home equity loans are less than half of
          the national average of 19.99%?
        </span>
      </>
    ),
    // slideButtonItems: [
    //   {
    //     label: `Today's Rate`,
    //     action: () => setRate(4.95)
    //   }
    // ],
    actionButtonItems: [
      {
        label: 'Get Started',
        href: 'https://products.nuborrow.com/#home_equity_basic_detail',
        isPrimary: true
      }
    ]
  };

  const [rateComparisonItems, setRateComparisonItems] = useState<RateComparisonItem[]>([
    {
      logo: Nuborrow,
      primary: {
        highlight: '4.95%',
        label: 'Prime Rate +'
      },
      secondary: {
        highlight: '60% LTV',
        label: 'Loan on Value'
      }
    },
    {
      logo: Nuborrow,
      primary: {
        highlight: '6.99%',
        label: 'Prime Rate +'
      },
      secondary: {
        highlight: '80% LTV',
        label: 'Loan on Value'
      }
    },
    {
      logo: Nuborrow,
      primary: {
        highlight: '9.89%',
        label: 'Prime Rate +'
      },
      secondary: {
        highlight: '85% LTV',
        label: 'Loan on Value'
      }
    }
  ]);

  const rateComparison: RateComparisonProps = {
    title: `Today's best rates on a Home Equity Loan (Second Mortgage).`,
    items: rateComparisonItems,
    effectiveDate: effectiveDate
  };

  const handleState = (state: RootState) => {
    setRate(state.findLowestRateForProduct('Equity Loan')?.rate ?? 0);

    if (!state.productRates['Equity Loan']) {
      return;
    }

    const list = state.findLowestRatesForProduct('Equity Loan', 3);
    const items: RateComparisonItem[] = list.map((bank, index) => ({
      logo: state.getLogoForBank(bank.name),
      primary: {
        highlight: `${bank.rate}%`,
        label: 'Prime Rate +'
      },
      secondary: {
        highlight: `${bank.ltv}% LTV`,
        label: 'Loan on Value'
      }
    }));

    setEffectiveDate(new Date(state.productRates['Equity Loan'].effectiveDate));
    setRateComparisonItems(items);
  };

  return (
    <Layout pageTitle={'Home Equity Loan'} onRootState={handleState}>
      <RateBanner {...rateProps} />
      <RateComparison {...rateComparison} />
      <TotalSavings value={6200000} />
      <FullBlock />
      {/* <StatementBlock /> */}
      <Reviews />
      <EquityIntoCash />
      {/* <GoodbyeDebt /> */}
      <SaveMoney />
      <UseEquity />
      {/* <FeelGood /> */}
      <TrackYourApp />
      <DownloadApp />

      <TrackingPixel src="https://geo-tracker.ads.thinkmidbrain.com/hyperad/pixel-tracking?order=112933&action=landing" />
    </Layout>
  );
}
